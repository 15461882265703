<template>
  <v-timeline-item small class="timeline">
    <template v-slot:icon>
      <v-avatar size="26" color="#ffffff">
        <v-icon class="text-gradient" :class="{'text-danger': item.warnChecks.length}"  size="16" >
          {{ item.warnChecks.length ? 'ni-time-alarm' : 'ni-check-bold' }}
        </v-icon>
      </v-avatar>
    </template>

    <v-card>
      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0 pt-0 pb-1 d-flex justify-space-between align-center">
            <div>
              <h6 class="text-dark text-sm font-weight-bold mb-0 d-block">
                <a @click="detail(item, 'tab-articles')" class="text-dark text-decoration-none">
                  {{item.name}}
                </a>
              </h6>
              <p class="text-secondary font-weight-bold text-xs mb-0">{{ toDate(item.createdAt) }}</p>
            </div>
            <a @click="detail(item, 'tab-summary')" class="text-typo text-xs text-decoration-underline">show complete check</a>
          </v-card-title>
          <v-card-text class="px-0">
            <v-alert dark class="alert alert-danger mt-3 mb-0 card-shadow" style="border-color: transparent !important;">
              <div class="d-flex align-center justify-lg-space-between">
                <div>
                  <span class="text-danger text-sm me-2 text-white">
                    <v-icon class="me-1">fas fa-exclamation-triangle</v-icon> <strong>{{item.warnChecks.length}} articles</strong>
                  </span>
                  <span class="text-white text-sm">has low score</span>
                </div>
                <a @click="detail(item, 'tab-articles')" class="ml-4 text-sm text-lowercase text-decoration-underline">show articles</a>
              </div>
            </v-alert>

            <v-row class="mt-3 mb-3">
              <v-col cols="12" md="6">
                <div class="text-sm text-body">
                  <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
                    <p class="text-secondary font-weight-bold text-xs mb-0">Last check changes</p>
                    <v-row no-gutters>
                      <v-col v-for="t in item.trends" sm="4">
                        <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">
                          <span class="me-1" style="display: inline-block; border-radius: 50%; width: 8px; height: 8px;" v-bind:style="{background: t.color}"></span>
                          {{ t.name }}
                        </p>
                        <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                          <v-icon v-if="t.trend" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="mt-1">fas fa-arrow-{{ t.trend }} me-2</v-icon>
                          <span v-if="t.diff" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="text-sm font-weight-bolder">{{ t.diff }}%</span>
                          <span v-if="!t.diff" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="text-sm font-weight-bolder">{{ t.resultInPercents }}%</span>
                        </h5>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <esg-line-chart v-if="item.lineChart"
                                :id="'feed-item-chart-' + item.id"
                                :labels="item.lineChart.labels"
                                :datasets="item.lineChart.datasets"
                                :show-card="true"
                ></esg-line-chart>
              </v-col>

            </v-row>



          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-timeline-item>
</template>
<script>
import moment from 'moment'
import ESGLineChart from '../../Widgets/Charts/LineChart.vue'

export default {
  name: 'feed-check-item',

  components: {
    'esg-line-chart': ESGLineChart
  },

  props: {
    item: Object,
    detail: {type: Function}
  },

  data() {
    return {}
  },

  created() {},

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    }
  }
}
</script>

<style></style>

