<template>
  <div>
    <v-navigation-drawer :stateless="specialDrawer" v-model="articlesDrawer" fixed temporary right :width="900" :hide-overlay="false" tag="div" class="drawer--sticky">
      <feed-check-detail :item="articlesCompany" :tab="articlesTab" @close="articlesDrawer = false" @showBings="showSpecialDrawer($event)"></feed-check-detail>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="specialDrawer" fixed temporary right :width="900" :hide-overlay="true" tag="div" class="drawer--sticky">
      <feed-check-articles :check="specialCheck" :bings="specialBings" @close="specialDrawer = false"></feed-check-articles>
    </v-navigation-drawer>

    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl mb-30">
            <div class="card-header-padding d-flex align-center">
              <h6 class="font-weight-bold text-h6 text-typo mb-0">Feed</h6>
              <feed-filter :by="filterBy" :filtered="filterChanged"></feed-filter>
            </div>

            <v-slide-x-transition leave-absolute>
              <div v-if="pending" class="my-5 mx-auto">
                <lottie-player src="https://assets10.lottiefiles.com/packages/lf20_s9bitkaj.json" mode="bounce" background="transparent"  speed="2" loop  autoplay style="margin: auto; max-width: 30vw;"></lottie-player>
              </div>
            </v-slide-x-transition>

            <v-card-text class="card-padding px-0 pt-0">
              <v-slide-y-transition>
                <v-timeline v-if="!pending" dense align-top class="timeline-line-color">
                  <feed-check-day v-for="(value, key) in groups"
                                   :key="'feed-' + key"
                                   :checks="value"
                                   :detail="showDrawer"
                  ></feed-check-day>
                </v-timeline>

<!--                <v-timeline v-if="filteredTimeline.length" dense align-top class="timeline-line-color">-->
<!--                  <feed-check-item v-for="(item, i) in filteredTimeline"-->
<!--                             :key="'feed-' + item.id"-->
<!--                             :item="item"-->
<!--                             :detail="showDrawer"-->
<!--                  ></feed-check-item>-->
<!--                </v-timeline>-->
              </v-slide-y-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import FeedFilter from '@/views/Pages/Feed/FeedFilter'
import FeedCheckItem from '@/views/Pages/Feed/FeedCheckItem'
import FeedCheckDay from '@/views/Pages/Feed/FeedCheckDay'
import FeedCheckDetail from '@/views/Pages/Feed/FeedCard'
import FeedCheckArticles from '@/views/Pages/Feed/FeedCheckArticles'
import {groupBy} from 'lodash'
import {CheckFactory} from '@/entities/CheckFactory'

export default {
  name: 'feed',
  components: {
    'feed-filter': FeedFilter,
    'feed-check-item': FeedCheckItem,
    'feed-check-day': FeedCheckDay,
    'feed-check-detail': FeedCheckDetail,
    'feed-check-articles': FeedCheckArticles
  },
  data() {
    return {
      pending: false,

      specialDrawer: null,
      specialCheck: null,
      specialBings: [],

      articlesDrawer: null,
      articlesCompany: null,
      articlesTab: 'tab-summary',
      filterBy: 'news',
      timeline: [],
      filteredTimeline: [],
      groups: {}
    };
  },
  created() {
    this.fetch()
  },

  methods: {
    showDrawer(check, tab) {
      this.articlesTab = tab
      this.articlesCompany = {
        check: check,
        company: this.$store.state.companies.find(i => i.company_name === check.name)
      }
      this.articlesDrawer = !this.articlesDrawer
    },

    async fetch() {
      this.pending = true
      try {
        const {data: companies} = await this.$store.getters.find('company', 0, 20, '"company_name" asc')
        this.$store.commit('setCompanies', companies)
        console.log(companies)

        // const {data: checks} = await this.$store.getters.request(`esg-plausibility-check/by-companies?companies=${companies.map(i => i.company_name).join(',')}`, 'GET')
        let {data: checks} = await this.$store.getters.find('esg-plausibility-check', 0, 20, '"created_on" desc')

        const countries = await CheckFactory.fetchCountries()

        // for (const company of companies) {
            checks = await CheckFactory.createPlausibilityCheck({checks}, countries)
            // checks = [...checks, ...companyChecks]
        // }
        //
        // console.log(checks)
        //
        this.timeline = checks.map(i => {
          i.calcPrevChecks(checks)
          i.setSectorProgressLineChart()
          return i
        })

          console.log(this.timeline)

        //
        // this.groups = groupBy(this.timeline, 'createdAtDay')
        //
        // if (this.filterBy === 'warnings') {
        //   this.filteredTimeline = this.timeline.filter(i => i.warnChecks.length)
        // } else {
        //   this.filteredTimeline = [...this.timeline]
        // }

        this.pending = false
      } catch (e) {
        console.error('Cannot fetch companies checks', e)
        // this.$store.commit('setCompanies', [])
        this.pending = false
      }
    },

    filterChanged(filterBy) {
      this.filterBy = filterBy
      this.filteredTimeline = []
      this.fetch()
    },

    showSpecialDrawer(event) {
      console.log(event)
      this.specialDrawer = true
      this.specialCheck = event.check
      this.specialBings = event.bings
    }
  }
};
</script>

<style></style>
