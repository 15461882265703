<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl mb-30">
            <div class="card-header-padding d-flex align-center">
              <h6 class="font-weight-bold text-h6 text-typo mb-0">Fund scans</h6>
            </div>

            <v-slide-x-transition leave-absolute>
              <div v-if="pending" class="my-5 mx-auto">
                <lottie-player src="https://assets10.lottiefiles.com/packages/lf20_s9bitkaj.json" mode="bounce" background="transparent"  speed="2" loop  autoplay style="margin: auto; max-width: 30vw;"></lottie-player>
              </div>
            </v-slide-x-transition>

            <v-card-text class="card-padding px-0 pt-0">
              <v-slide-y-transition>
                <v-timeline v-if="!pending" dense align-top class="timeline-line-color">
                  <div v-for="scan in timeline" :key="'feed-' + scan._id">
                    <fund-scan-item :scan="scan"></fund-scan-item>
                  </div>
                </v-timeline>
              </v-slide-y-transition>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {FundScan} from '@/entities/FundScan'
import FundScanItem from '@/views/Pages/FundScans/FundScanItem'

export default {
  name: 'feed',
  components: {
    FundScanItem
  },
  data() {
    return {
      pending: false,

      specialDrawer: null,
      specialCheck: null,
      specialBings: [],

      articlesDrawer: null,
      articlesCompany: null,
      articlesTab: 'tab-summary',
      filterBy: 'news',
      timeline: [],
      filteredTimeline: [],
      groups: {}
    };
  },
  created() {
    this.fetch()
  },

  methods: {
    async fetch() {
      this.pending = true
      try {
        const {data: scans} = await this.$store.state.api.get('/fund/scans?page=0&perPage=50&sort=%22created_on%22asc')
        // const {data: scans} = await this.$store.getters.find('fund_scans', 0, 1000, '"fund_name" asc')
        this.timeline = scans.result.map(i => new FundScan(i))
        this.pending = false
      } catch (e) {
        console.error('Cannot fetch fund scans checks', e)
        this.pending = false
      }
    }
  }
};
</script>

<style></style>
