<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" :ripple="false" height="34" color="transparent" small
             class="font-weight-bold btn-default btn-outline-default ms-3" :class="activeFilter.className"
      >
        {{activeFilter.label}}
        <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
      </v-btn>
    </template>

    <v-list class="py-2">
      <v-list-item v-for="i in filters" @click="filterChanged(i.key)" class="list-item-hover-active py-2">
        <v-list-item-content>
          <v-list-item-title class="text-body-2 ls-0 text-typo font-weight-600 mb-0" :class="i.className">{{i.label}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>

export default {
  name: 'feed-filter',

  components: {},

  props: {
    by: String,
    filtered: Function
  },

  data() {
    return {
      filters: [
          {key: 'news', label: 'All news'},
          {key: 'today', label: 'Today'},
          {key: 'warnings', label: 'Warnings', className: 'text-danger'},
      ],
      filterBy: null,
      activeFilter: null
    }
  },

  created() {
    this.filterBy = this.by
    this.setActiveFilter()
  },

  methods: {
    filterChanged(f) {
      this.filterBy =  f
      this.setActiveFilter()
      this.filtered(this.filterBy)
    },

    setActiveFilter() {
      this.activeFilter = this.filters.find(i => i.key === this.filterBy)
    }
  }
}
</script>

<style></style>

