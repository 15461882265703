<template>
  <v-timeline-item small class="timeline">
    <template v-slot:icon>
      <v-avatar size="26" color="#ffffff">
          <v-icon class="text-green" size="16">ni-check-bold</v-icon>
      </v-avatar>
    </template>

    <div>
      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0 pt-0 pb-1 d-flex justify-space-between align-center">
            <div>
              <h6 class="text-sm font-weight-bold mb-0 d-block">
                <router-link :to="{name: 'Fund', params: {id: scan._id}}" class="text-dark">{{scan.fund_name}}</router-link>
              </h6>
              <p class="text-secondary font-weight-bold text-xs mb-0">{{ toDate(scan.created_at || new Date()) }}</p>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
    </div>
  </v-timeline-item>
</template>
<script>
import moment from 'moment'

export default {
  name: 'fund-scan-item',

  components: {},

  props: {
    scan: Object
  },

  data() {
    return {
      day: null
    }
  },

  computed: {},

  created() {},

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    }
  }
}
</script>

<style></style>
