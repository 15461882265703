<template>
  <v-timeline-item small class="timeline">
    <template v-slot:icon>
      <v-avatar size="26" color="#ffffff">
        <v-icon class="text-gradient" :class="{'text-danger': warnings.length}"  size="16" >
          {{warnings.length ? 'ni-time-alarm' : 'ni-check-bold'}}
        </v-icon>
      </v-avatar>
    </template>

    <div v-if="day">
      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0 pt-0 pb-1 d-flex justify-space-between align-center">
            <div>
              <h6 class="text-dark text-sm font-weight-bold mb-0 d-block">
                <a @click="detail(day, 'tab-articles')" class="text-dark text-decoration-none">
                  {{day.createdAtDay}}
                </a>
              </h6>
              <p class="text-secondary font-weight-bold text-xs mb-0">{{ toDate(day.createdAt) }}</p>
            </div>

          </v-card-title>

          <v-row>
            <v-col v-for="check in checks" cols="12" md="6" xl="4">
              <v-card elevation="5" class="py-1 px-5">
                <v-card-text class="px-0">
                  <div class="d-flex justify-space-between align-center">
                    <p class="text-body-1 font-weight-bold text-xs mb-0">{{check.name}}</p>
                    <a @click="detail(check, 'tab-summary')" class="text-typo text-xs text-decoration-underline">show complete check</a>
                  </div>


                  <v-alert v-if="check.warnings.length" text type="error" class="mt-2 text-sm no-icon" border="left">
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        <v-icon class="me-3 text-red">fas fa-exclamation-triangle</v-icon>
                        <strong>{{check.warnings.length}} articles</strong> <span>with low sentiment score</span>
                      </div>
                      <v-btn @click="detail(check, 'tab-articles')" color="error" small outlined>show</v-btn>
                    </div>
                  </v-alert>

                  <v-row>
                    <v-col cols="12">
                      <div class="text-sm text-body">
                        <v-card class="card-shadow border-radius-xl px-4 py-4">
                          <v-row no-gutters>
                            <v-col v-for="t in check.trends" sm="4">
                              <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">
                                <span class="me-1" style="display: inline-block; border-radius: 50%; width: 8px; height: 8px;" v-bind:style="{background: t.color}"></span>
                                {{ t.name }}
                              </p>
                              <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                                <v-icon v-if="t.trend" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="mt-1">fas fa-arrow-{{ t.trend }} me-2</v-icon>
                                <span v-if="t.diff" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="text-sm font-weight-bolder">{{ t.diff }}%</span>
                                <span v-if="!t.diff" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="text-sm font-weight-bolder">{{ t.resultInPercents }}%</span>
                              </h5>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <esg-line-chart v-if="check.lineChart"
                                      :id="'feed-item-chart-' + check.id"
                                      :labels="check.lineChart.labels"
                                      :datasets="check.lineChart.datasets"
                                      :show-card="true"
                      ></esg-line-chart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-timeline-item>
</template>
<script>
import moment from 'moment'
import ESGLineChart from '../../Widgets/Charts/LineChart.vue'

export default {
  name: 'feed-check-day',

  components: {
    'esg-line-chart': ESGLineChart
  },

  props: {
    checks: Array,
    detail: {type: Function}
  },

  data() {
    return {
      day: null
    }
  },

  computed: {
    warnings() {
      return this.checks.reduce((all, i) => [...all, ...i.warnings], [])
    }
  },

  created() {
    this.day = this.checks[0]
  },

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    }
  }
}
</script>

<style></style>
